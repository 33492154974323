<template>
  <div class="tablePage">
    <h4 class="header">当前位置： 基础练习记录 / 数据统计</h4>
    <a-form
      :model="where"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="病例名称:" labelAlign="right">
            <a-select
              v-model:value="where.caseId"
              show-search
              placeholder="请输入病例名称或选择"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="(item, index) in roleNameData"
                :key="index"
                :label="item.caseName"
                :value="item.caseId"
                >{{ item.caseName }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="完成时间:" labelAlign="right">
            <a-range-picker
              v-model:value="where.finishDate"
              show-time
              value-format="YYYY-MM-DD hh:mm:ss"
              class="ele-fluid"
              @change="finishDateChange"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item class="ele-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-card :bordered="false">
      <a-table
        :rowKey="(record,index)=>{return index}"
        :pagination="false"
        :dataSource="dataSource"
        :columns="columns"
        @change="handleTableChange"
      >
        <template #rate="{ record }">
          <a-space>
            {{(record.finishScore / record.score * 100)?.toFixed(2)??0}}%
          </a-space>
        </template>
        <template #normalFinishTime="{ record }">
          <a-space>
            {{formateSeconds(record.normalFinishTime)}}
          </a-space>
        </template>
        <template #finishTime="{ record }">
          <a-space>
            {{formateSeconds(record.finishTime)}}
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a class="showLog" @click="showLog(record,'caseLog')">查看</a>
          </a-space>
        </template>
        <template #errAction="{ record }">
          <a-space>
            <a class="showLog" @click="showLog(record,'caseWrongLog')">查看</a>
          </a-space>
        </template>
      </a-table>
    </a-card>
    <div class="foote">
      <a-pagination @change="handlePaginationChange" v-model:current="page" :total="total" show-less-items :show-total="total => `共${total}条数据`"  />
    </div>
    <log v-model:visible="visible" :current="current" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import  {formateSeconds} from '@/utils/tool'
import log from './modules/log.vue';
export default {
  name: "BasicPractiseTable",
  components:{
    log
  },
  data() {
    return {
      formateSeconds,
      page: 1,
      total:500,
      // 表格搜索条件
      where: {},
      //筛选key
      orderBy: "",
      //筛选value 0默认  1 desc降序 2 asc升序
      sort: 0,
      // 表格选中数据
      selection: [],
      dataSource: [],
      roleNameData: [],
      visible:false,
      // 日志数据
      current: {},
      columns: [
        {
          title: "病例号",
          dataIndex: "caseNumber",
          key: "caseNumber",
          width: 100,
          align:'center',
          ellipsis: true,
        },
        {
          title: "病例名称",
          dataIndex: "caseName",
          key: "caseName",
          // width:300,
        },
        {
          title: "完成时间",
          dataIndex: "finishDate",
          key: "finishDate",
          sorter: true,
          ellipsis: true,
        },
        {
          title: "分值",
          dataIndex: "score",
          key: "score",
          // width:100,
          ellipsis: true,
        },
        {
          title: "得分",
          dataIndex: "finishScore",
          // width:100,
          key: "finishScore",
        },
        {
          title: "得分率",
          key: "rate",
          // width: 100,
          slots: { customRender: "rate" },
        },
        {
          title: "错误次数",
          dataIndex: "wrongNumber",
          key: "wrongNumber",
          sorter: true,
          // width: 140,
        },
        {
          title: "一般完成时间",
          slots: { customRender: "normalFinishTime" },
          // dataIndex: "normalFinishTime",
          key: "normalFinishTime",
        },
        {
          title: "实际完成时间",
          slots: { customRender: "finishTime" },
          // dataIndex: "finishTime",
          key: "finishTime",
        },
        {
          title: "病例日志",
          key: "log",
          width:100,
          slots: { customRender: "action" },
        },
        {
          title: "错误日志",
          key: "errLog",
          width:100,
          slots: { customRender: "errAction" },
        },
      ],
    };
  },
  created() {
    //获取table
    this.getTable();
    //获取案例
    this.getRoleName();
  },
  methods: {
    filterOption(input,option){
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    showLog(res={},type){
      this.current = {
        memberCaseId:res.memberCaseId,
        type:type
      }
      this.visible = true;
    },
    finishDateChange(time,timeString){
      this.where.finishDateStart = time[0] || '';
      this.where.finishDateEnd = time[1] || '';
    },
    //table排序
    handleTableChange(pagination, filters, sorter) {

      this.orderBy = sorter.order == "ascend" || sorter.order == "descend" ? sorter.field : '';
      this.sort = sorter.order == "ascend"?'asc':sorter.order == "descend"?'desc':'';
      this.page = 1;
      this.$nextTick(()=>this.getTable());
    },
    //分页改变
    handlePaginationChange(page, pageSize){
      this.page = page;
      this.$nextTick(() => this.getTable());
    },
    /* 搜索 */
    reload() {
      this.page = 1;
      this.$nextTick(() => this.getTable());
    },
    /*  重置搜索 */
    reset() {
      this.where = {}; 
      this.page = 1;
      this.$nextTick(() => this.getTable());
    },
    async getTable() {
      try {
        let res = await this.$http.post(this.$interface.missionLog, {
          page: this.page,
          pageSize: 10,
          type:'test',
          orderBy:this.orderBy,
          sort:this.sort || '',
          ...this.where,
        });
        let data = res.data || {};
        this.total = data.count;
        this.dataSource = data.data || [];
      } catch (err) {}
    },
    async getRoleName() {
      try {
        let res = await this.$http.get(this.$interface.myExerciseSelect);
        this.roleNameData = res.data.data || [];
      } catch (err) {}
    },
  }, 
  computed: {
    ...mapGetters(["classIndex"]),
  },
  watch:{
    classIndex(val){
      this.getRoleName();
      this.reset();
    }
  }
};
</script>
<style lang="scss">
@import "index.scss";
</style>